.manageUser {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  &__navbar {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
