.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  // height: 100%;
  flex: 1 0 auto;
  width: 100%;
  &__mainContainer,
  &__formContainer {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    width: 88%;
  }
  &__mainContainer {
    // height: 100%;
    width: 100%;
    flex: 1 0 auto;
  }
}
