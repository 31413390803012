.blogItem {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 2rem;
  padding: 5rem;
  //   align-items: center;
  justify-content: center;
  width: 100%;
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 40rem;
  }
  &__contentContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    color: #000;
    &__body {
      font-size: 1.8rem;
      font-weight: 600;
      text-align: left;
      word-break: break-word;
    }
    &__header{
      background-color: darkgray;
    }
  }
  
}
