@import "../../assets/sass/abstracts/variables";

.searchDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  height: 100%;
  width: 100%;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 2.2rem;
    color: $font-PrimaryColor;
  }
  &__profilesListContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    &__heading {
      font-weight: 400;
      font-size: 1.6rem;
    }
    &__list {
      display: grid;
      // align-items: center;
      grid-template-columns: repeat(10, 1fr);
      justify-content: space-between;
      gap: 0.5rem;
      &__item {
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
