.activeProfileInfoContainer {
  display: flex;
  // align-items: center;
  justify-content: center;
  gap: 7rem;
  width: 100%;
  padding: 2rem 0rem;
  &__imageContainer {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    gap: 5rem;
    &__buttonContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}
