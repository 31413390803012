.privacyPolicyMain {
  padding: 2rem 4rem;
  // media (max-width: 600px) {
  //   padding: 2rem;
  // }
  .heading {
    margin-top: 1rem;
    font-size: 1.6rem;
    font-weight: bold;
    //   @media (min-width: 1600px) {
    //     font-size: 18px;
    //   }
  }
  h3 {
    font-size: 1.6rem;
    color: #000000;
  }
  h2 {
    font-size: 1.6rem;
  }
  .container {
    padding-left: 5.4rem;
    padding-right: 5.4rem;
    margin-top: 1.8rem;
    //     @media (min-width: 1600px) {
    //       font-size: 17px;
    //   }
  }
  .header {
    font-weight: bold;
    font-size: 1.6rem;
    margin-top: 2rem;
    // @media (min-width: 1600px) {
    //   font-size: 18px;
    // }
  }
  .subheader {
    font-size: 1.5rem;
    margin-top: 2rem;
    font-weight: bold;
    // margin-left: 2.0rem;
  }
  .subheaderInfo {
    // margin-left: 2.0rem;
    // margin-top: 2.0rem;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .subheaderInfoSub {
    margin-left: 3.5rem;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  P {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  u {
    text-decoration: underline;
    font-size: 1.5rem;
    color: #4242fb;
    font-weight: 500;
  }
  .body {
    // padding-top: 2.2rem;
    padding-bottom: 1.5rem;
    // margin-left: 2.0rem;
  }
  .PrivacyPolicyLink {
    margin-left: 3.5rem;
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    color: #4242fb;
    cursor: pointer;
    display: flex;
  }
}
