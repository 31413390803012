.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url(assets/images/AppBackground.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  position: relative;
  &__main {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex: 1 0 auto;
    flex-direction: column;
    padding-top: 4rem;
    // height: 100%;
  }
}
