$sidePanel-BGColor: #244d62;
$sidePanel-BoxShadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.25);
$sidePanel-Color: #fff;

.sidePanel {
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 18.5rem;
    padding: 3rem 0rem;
    // height: 29rem;
    background: $sidePanel-BGColor;
    box-shadow: $sidePanel-BoxShadow;
    border-radius: 0rem 2rem 2rem 0rem;
    gap: 2rem;
    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      color: $sidePanel-Color;
      position: relative;
      gap: 0.7rem;
      cursor: pointer;
      &__active {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
