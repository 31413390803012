.manageUser {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 5%;
  &__searchAndInfo {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-size: 2.2rem;
  }
}
