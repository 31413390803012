@import "../../../assets/sass/abstracts/variables";
$modal-cancelColor: #244d62;

.modal__wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 98;
  }
  &__content {
    position: relative;
    background-color: $color-PrimaryBGColor;
    border: 0.1rem solid #707070;
    padding: 1.5rem 2rem;
    box-shadow: 0.2rem 0.2rem 1rem rgba($color: #000, $alpha: 0.3);
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 2.2rem;
      color: $font-PrimaryColor;
      position: relative;
      width: 100%;
      &__close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: transparent;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__body {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    &__footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 2rem;
      font-size: 1.8rem;
      &__cancel {
        color: $modal-cancelColor;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
