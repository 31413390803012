@import "../../assets/sass/abstracts/variables";

.topProfiles {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2.4rem;
  padding-right: 5rem;
  &__heading {
    font-weight: 400;
    font-size: 2.2rem;
    color: $font-PrimaryColor;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 55vh;
    width: 32rem;
    overflow-y: auto;
  }
}
