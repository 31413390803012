@import "../../../assets/sass/abstracts/mixins";

.profileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0rem 7rem;
  gap: 2rem;
  @include mobile {
    padding: 0rem 2rem;
  }
  @include tablet {
    padding: 0rem 5rem;
  }
  &__navbar {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__infoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7rem;
    height: 100%;
    // width: 80%;
    @include mobile {
      gap: 3rem;
      flex-direction: column;
    }
    @include tablet {
      gap: 5rem;
      flex-direction: column;
    }
  }
}
