.admin {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 15% 85%;
  width: 100%;
  height: 100%;
  &__leftPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    &__sidePanel {
      position: fixed;
      top: 50%;
      left: 0%;
      transform: translateY(-50%);
    }
  }
}
