.aboutUs {
  display: flex;
  flex-direction: column;
  padding: 0rem 7rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  &__header {
    font-weight: 400;
    font-size: 2.2rem;
  }
  &__body {
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    gap: 10rem;
    &__description {
      text-align: center;
    }
    &__keyPeople {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 3rem;

      &__heading {
        font-weight: 400;
        font-size: 2.2rem;
      }
      &__list {
        display: flex;
        // align-items: center;
        justify-content: center;
        gap: 6rem;
        flex-wrap: wrap;
        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          //   justify-content: center;
          gap: 1rem;
          &__name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            text-align: center;
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}
