@mixin smallMobile {
  @media (max-width: 464px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1024px) and (max-width: 1439px) {
    @content;
  }
}
// @mixin desktop {
//   @media (min-width: 1024px) {
//     @content;
//   }
// }
