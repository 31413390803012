@import "../../../components/common/Input/Input.module.scss";

.searchForm {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  background-color: #444444;
  padding: 2rem 3rem;
  &__fieldList {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 2rem;
    &__field {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 2rem;
      &__tag {
        height: 4.6rem;
        text-align: center;
        display: flex;
        align-items: center;
        color: $input-LabelColor;
      }
    }
    &__reset {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      cursor: pointer;
      color: #fff;
      height: 4.6rem;
      font-size: 1.8rem;
    }
  }
  // &__buttonContainer {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
}
