$sidePanel-Color: #fff;
$sidePanel-BGColor: #244d62;
$sidePanel-BoxShadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.25);

.sidebar {
  position: absolute;
  right: 100%;
  top: 0%;
  z-index: 10;
  height: 100vh;
  width: 28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $sidePanel-BGColor;
  box-shadow: $sidePanel-BoxShadow;
  cursor: default;
  transform: translateX(18%);
  transition: transform 300ms ease-in;
  &__navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-direction: column;
    padding: 3rem;
    a {
      color: $sidePanel-Color;
    }
  }
}
.sidebar__show {
  transform: translateX(100%);
}
.sidebar__hide {
  transform: translateX(0%);
  right: 100%;
}
