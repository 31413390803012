.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: url("../../../assets/images/loginBG.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  min-height: 80vh;
}
