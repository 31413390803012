@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/mixins";

$profileInfo-Color: $font-PrimaryColor;
$profileInfo-HeaderFontSize: 2rem;
$profileInfo-FontSize: 1.6rem;

.fullProfileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 3rem;
  color: $profileInfo-Color;
  font-size: $profileInfo-FontSize;
  padding: 1rem 0rem;
  @include mobile {
    text-align: center;
  }
  &__header {
    font-size: $profileInfo-HeaderFontSize;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__infoContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    &__field {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      &__heading {
        font-weight: bold;
      }
      &__value {
        font-weight: 400;
      }
    }
    &__columns {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      @include mobile {
        grid-template-columns: repeat(1, 1fr);
      }
      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }
      &__column1 {
        display: grid;
        grid-template-rows: repeat(5, auto);
        gap: 2rem;
      }
      &__column2 {
        display: grid;
        grid-template-rows: repeat(4, auto);
        gap: 2rem;
      }
      &__column3 {
        display: grid;
        grid-template-rows: repeat(4, auto);
        gap: 2rem;
      }
    }
  }
}
