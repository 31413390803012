$modal-cancelColor: #244d62;

.forgotPassword {
  display: grid;
  grid-template-rows: 80% 15%;
  height: 100%;
  width: 100%;
  align-items: center;
  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    font-size: 1.8rem;
    &__cancel {
      color: $modal-cancelColor;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
