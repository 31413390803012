@import "../../../assets/sass/abstracts/mixins";

.searchList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 88%;
  gap: 1.5rem;
  padding: 1.5rem 0rem;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: bold;
    width: 100%;
    &__searchDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      &__count {
        font-weight: 400;
        padding-left: 0.5rem;
      }
    }
    &__reset {
      cursor: pointer;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // display: flex;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1.8rem;
    width: 100%;
    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include laptop {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
