@import "../../assets/sass/abstracts/variables";

.userForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;
  padding: 4rem 2rem;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__heading {
      font-weight: 400;
      font-size: 2.2rem;
      color: $font-PrimaryColor;
    }
    &__buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4rem;
      &__reset {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        cursor: pointer;
      }
    }
  }
  &__basicDetails {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    &__fields {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      align-items: center;
      :last-child {
        grid-column: 1 / 5;
      }
    }
    &__relationsfields {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &__field {
        display: flex;
        gap: 2rem;
        align-items: flex-end;
        &__delete {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 4.6rem;
          svg {
            height: 100%;
            width: 2.5rem;
          }
        }
      }
    }
  }
  &__addressDetails {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    &__presentAddress {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &__heading {
        font-weight: 400;
        font-size: 2.2rem;
        color: $font-PrimaryColor;
      }
      &__fields {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 2rem;
        align-items: center;
        :first-child {
          grid-column: 1 / 5;
        }
      }
    }
    &__permanentAddress {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &__heading {
        font-weight: 400;
        font-size: 2.2rem;
        color: $font-PrimaryColor;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &__input {
          font-size: 1.5rem;
        }
      }
      &__fields {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 2rem;
        align-items: center;
        :first-child {
          grid-column: 1 / 5;
        }
      }
    }
  }
}
